.sub_text_bg {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.all_service_card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 25px 50px 25px 25px;
    gap: 20px;
    background: linear-gradient(180deg, #252427 0%, #0F0F10 100%);
    /* box-shadow: 70px 65px 27px rgba(0, 0, 0, 0.01), 45px 41px 24px rgba(0, 0, 0, 0.07), 25px 23px 21px rgba(0, 0, 0, 0.24), 11px 10px 15px rgba(0, 0, 0, 0.4), 3px 3px 8px rgba(0, 0, 0, 0.47), 0px 0px 0px rgba(0, 0, 0, 0.47); */
    box-shadow: -4px 4px 0 0 #18D678;
    border-radius: 100px;
    flex-grow: 1;
}

.all_service_card_img {
    padding: 20px;
    width: 70px;
    height: 70px;
    background: linear-gradient(180deg, #251732 0%, #160D1E 100%);
    box-shadow: 70px 65px 27px rgba(0, 0, 0, 0.01), 45px 41px 24px rgba(0, 0, 0, 0.07), 25px 23px 21px rgba(0, 0, 0, 0.24), 11px 10px 15px rgba(0, 0, 0, 0.4), 3px 3px 8px rgba(0, 0, 0, 0.47), 0px 0px 0px rgba(0, 0, 0, 0.47);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;



}

.purpleBg {
    background: radial-gradient(100% 100% at 50% 100%, #BE74FB 0%, #000000 100%);
}

.old_knoct {
    background: linear-gradient(180deg, #252427 0%, #0F0F10 100%);
    border-radius: 50px;
     padding-right: 50px;
    padding-left:50px;
    padding-top:50px
}

.new_knoct {
    background: linear-gradient(180deg, #46DE93 0%, #18D678 100%);
    border-radius: 50px;
    padding-right: 50px;
    padding-left:50px;
    padding-top:50px

}

.service_text{
    font-size: 35px;
}



@media (max-width: 767px) {

    .all_service_card_img {
        padding: 15px;
        width: 60px;
        height: 60px;
    }
    .service_text{
        font-size: 15px;
    }
}


@media ((min-width: 768px) and (max-width:992px)){

    .all_service_card_img {
        padding: 15px;
        width: 60px;
        height: 60px;
    }
  

}
@media ((min-width: 992px) and (max-width:1299px)) {
    .all_service_card {
        padding: 15px 25px 15px 15px;
        gap: 25px;
    }
  


}
@media (min-width: 1300px) {
    
}