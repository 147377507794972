* {
    margin: 0;
    padding: 0;
}

.HeroContainer {
    text-align: center;
    font-family: 'inter';

}

.HeroBanner {
    margin: 5rem 6rem;
}

.ImgHero {
    text-align: center;
    margin: auto;
}

.blog-container {
    display: flex;
    flex-direction: row;

}


.ml-blog {
    margin: 4rem 5rem;
}

.blog-desc {


    text-align: left;
}

.Blog-heading {
    font-weight: 500;
    font-size: 64px;
    margin: 0.6rem 0;
    background: -webkit-linear-gradient(#0a0a0a 10%, #beb7c4 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.similar-heading {
    /* margin: 2rem 6rem; */
    text-align: left;
}

.blog-para {
    color: #beb7c4;
    font-weight: 500;
}

.page-button {
    width: auto;
    background-image: linear-gradient(#74e6ae, #18d678);
    color: #000;
    padding: 10px 24px;
    margin: 1rem 0;
    text-align: left;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    height: 48px;
    transition: 0.3s;
}

.page-button:hover {

    background-image: linear-gradient(#18d678, #74e6ae);

}

.FAarrow {
    margin: 5px 10px;

}

.blog-carousel-container {
    padding: 3rem;
    display: flex;
    width: 70%;
    flex-direction: column;
}

.search-container {
    margin: 1rem 0;
}

.search-input {
    background: #0f0f10;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 11px 12px;
    box-shadow: 0.5px 0 #888888, -0.5px .5px #888888;
    width: 180%;
}

.blog-search {
    margin-left: -32rem;
}

.search-input::placeholder {
    color: #98929c;
}

.search-icon {
    position: absolute;
    color: #98929c;
    margin: 1rem 0 0 1rem;
}

.WB-Container {
    flex-direction: column;
}

.wb-desc {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.wb-topbar {
    width: 70%;
    margin-left: 4rem;
}

.wb-search-div {
    margin: 1.5rem 0;
}

.wb-search {
    width: 40%;
}

.rightside-btn {
    margin-right: 5rem;
}

.viewBlogPage {

    text-align: left;
}

.blog-details {
    margin: 0 2rem;
}

.blog-details h1 {
    font-size: 48px;
}

.blog-details p {
    margin: 1rem 0;
}

.Desc-para {
    width: 65%;
    margin: 2rem 0;
}

.contexts {
    margin: 2rem;

}

.contexts h2 {
    font-size: 32px;
}


@media only screen and (max-width: 600px) {
    .blog-container {

        flex-direction: column;
        margin: 4rem 0;
    }

    .blog-carousel-container {
        padding: 1rem;
        display: flex;
        width: 100%;
        flex-direction: column;
    }
}