.knoct_pro_hero_child_header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.knoct_pro_hero_child_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.knoct_sub_normal_header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.exchange_sub_header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.exchange_sub_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.features_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #E5E2E7;
    text-align: start;
}


@media only screen and (max-width: 700px) {
    .knoct_pro_hero_child_text {
        font-size: 16px;
        line-height: 24px;
    }

    .features_text {
        font-size: 14px;
        line-height: 20px;
    }

    .knoct_sub_normal_header {
        font-size: 24px;

    }

    .exchange_sub_header {
        font-size: 18px;
    }


}

@media only screen and (max-width: 900px) {
    .knoct_pro_hero_child_text {
        font-size: 12px;
        line-height: 24px;
    }

    .features_text {
        font-size: 16px;
        line-height: 20px;
    }

    .knoct_sub_normal_header {
        font-size: 20px;

    }

    .knoct_pro_hero_child_header {
        font-size: 20px;
    }

    .exchange_sub_header {
        font-size: 24px;
    }

}

@media ((min-width: 992px) and (max-width:1299px)) {
    .exchange_sub_header {

        font-size: 20px;

    }

    .exchange_sub_text {

        font-size: 12px;
        line-height: 22px;

    }
}