@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html{
    scroll-behavior: smooth;
}

body {
    background-color: #000000;
    font-family: 'Inter', sans-serif;

}

.mobile_carousal {
    display: none;
}
.others_display_content{
    display: none;
}


.header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: clamp(30px,5vw,64px);
    line-height: 70px;
    letter-spacing: 0.04em;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.big-header {
    /* @apply text-white md:text-4xl text-2xl */
    margin: 0.6rem 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 104px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.knoct_header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: clamp(45px,5vw,96px);
    line-height: 116px;
    display: flex;
    align-items: center;
    text-align: center;

    /* text gradient gray */

    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.knoct_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #F8F9FB;
}

.main_padding {
    padding: 0 20px
}

.team_text {
    font-size: 24px;
}

.knoct_product_hero {
    background: linear-gradient(180deg, rgba(37, 36, 39, 0.5) 0%, rgba(15, 15, 16, 0.5) 100%);
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(19.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 18px;
}

.btn-solid-header {
    @apply text-black font-semibold bg-gradient-to-t from-[#74E6AE] to-[#18D678] shadow-lg lg:px-[36px] lg:py-[18px] md:px-[24px] md:py-[12px] md:text-lg lg:text-[30px] md:rounded-[18px] px-5 py-2 rounded-xl hover:to-[#74E6AE] hover:from-[#18D678] transition-all
}

.my_btn{
    @apply  bg-gradient-to-t from-[#74E6AE] to-[#18D678] hover:to-[#74E6AE] hover:from-[#18D678] transition-all
}

.btn-outline-header {
    @apply border-[#46DE93] border-[1.52px] text-white font-semibold md:text-[30px] shadow-lg lg:px-[36px] lg:py-[18px] md:px-[24px] md:py-[12px] md:text-lg lg:text-[30px] md:rounded-[18px] px-5 py-2 rounded-xl
}

.title_border {
    @apply border border-purple-200 md:px-[32px] md:py-[10px] rounded-[13px] md:text-[20px] px-4 py-1
}

/* .btn-solid {

    @apply text-black font-semibold bg-gradient-to-t from-[#74E6AE] to-[#18D678]   text-[20px] rounded-[12px] shadow-[0px_20px_40px_rgba(0,0,0,0.15)] px-[24px] py-[12px] hover:to-[#74E6AE] hover:from-green-700 duration-500
} */

.btn-solid{
padding: 12px 24px;
background: linear-gradient(180deg, #74E6AE 0%, #18D678 100%);

box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
border-radius: 12px;
color:#000000;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
}

.btn-solid:hover{
background:#46DE93;
box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
transition: all;
}

.btn-outline {
    @apply border-[#46DE93] border-[1.52px] text-white font-semibold md:px-[24px] md:py-[12px] md:text-[20px] rounded-[12px] text-sm py-[10px] px-[20px]
}

.hightlight {
    @apply bg-gradient-to-l to-green-950 from-purple-950
}

.cardBg {
background: linear-gradient(180deg, #252427 0%, #0F0F10 100%);

}

.normalBtn {
    @apply bg-gradient-to-t from-[#46DE93] to-green-500 text-black rounded-xl px-5 py-2 font-bold text-2xl shadow-lg
}

#sidebar a.active {
    @apply bg-gray-700 text-white border-none
}

#mainbar a.active {
    @apply text-green-400 font-bold bg-transparent
}

#mainbar a.active span {
    @apply border-b border-green-500
}

#mainbar>a>span {
    @apply border-b
}

.knoct_bg {
    background-image: linear-gradient(225.63deg, #8253B6 -7.5%, #009A55 104.39%)
}

.tech_bg {
    background: linear-gradient(225.63deg, #BE74FB -7.5%, #18D678 104.39%);
    box-shadow: 0px 4px 50px rgba(237, 237, 237, 0.06);
    filter: drop-shadow(72px 72px 29px rgba(200, 0, 255, 0.01)) drop-shadow(46px 46px 26px rgba(200, 0, 255, 0.05)) drop-shadow(26px 26px 22px rgba(200, 0, 255, 0.18)) drop-shadow(12px 12px 16px rgba(200, 0, 255, 0.3)) drop-shadow(3px 3px 9px rgba(200, 0, 255, 0.34)) drop-shadow(0px 0px 0px rgba(200, 0, 255, 0.35));
}

.icon_shadow {
    filter: drop-shadow(72px 72px 29px rgba(200, 0, 255, 0.01)) drop-shadow(46px 46px 26px rgba(200, 0, 255, 0.05)) drop-shadow(26px 26px 22px rgba(200, 0, 255, 0.18)) drop-shadow(12px 12px 16px rgba(200, 0, 255, 0.3)) drop-shadow(3px 3px 9px rgba(200, 0, 255, 0.34)) drop-shadow(0px 0px 0px rgba(200, 0, 255, 0.35));
}

.knoct_dash_shadow {
    filter: drop-shadow(0px 233.002px 93.3212px rgba(0, 0, 0, 0.04)) drop-shadow(0px 131.252px 78.8715px rgba(0, 0, 0, 0.13)) drop-shadow(0px 58.401px 58.401px rgba(0, 0, 0, 0.23)) drop-shadow(0px 14.4497px 31.9098px rgba(0, 0, 0, 0.26)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.27));
}

.enterPrize_text {
    background: linear-gradient(268.47deg, #46DE93 -0.25%, #CB8FFB 59.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

/* Define the keyframes for the animation */


.text_bg {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
}

.extra_padding {
    padding: 75px 150px
}

/* ===========Slide ============= */
.react-multi-carousel-list {
    align-items: flex-end !important;

}

li.react-multi-carousel-item.carousel-item-padding-40-px {
    padding-right: 30px !important;


}

.react-multiple-carousel__arrow--right {
    right: 0 !important;
    width: 10px;
}


.react-multiple-carousel__arrow--left {

    left: 0 !important;
}


.react-multiple-carousel__arrow {
    border-radius: 100% !important;
    position: absolute;
    outline: 0;
    transition: all .5s;
    border: 1px solid rgb(189, 189, 189) !important;
    background: white !important;
    min-width: 43px;
    min-height: 43 !important;
    bottom: 45%;
    z-index: 10 !important;
}

.react-multi-carousel-dot--active button {
    background-color: #05BC61 !important;

}

.react-multi-carousel-dot button {
    border-color: lightgray !important
}

.react-multiple-carousel__arrow::before {
    color: gray !important
}

.customer_optimize_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 63px;
    gap: 10px;
    width: 199px;
    height: 199px;
    background: linear-gradient(180deg, #252427 0%, #0F0F10 100%);
    box-shadow: 0px 4px 4px #36D888;
    border-radius: 100px;

    
}

.text_gray_shadow {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.contact_bg {
    padding: 25px;
    background: linear-gradient(180deg, rgba(37, 36, 39, 0.5) 0%, rgba(15, 15, 16, 0.5) 100%);
    backdrop-filter: blur(15px);

    border-radius: 12px;
}

.store_certificate {
    width: 340px;
    height: 200px;
}

::-webkit-scrollbar {
    display: none;
    width:0;
}

.colorful_bg {
    @apply bg-gradient-to-bl from-[#BE74FB] to-[#18D678] rounded-[50px]
}

.shadow_img {
    filter: drop-shadow(70px 65px 27px rgba(0, 0, 0, 0.01)) drop-shadow(45px 41px 24px rgba(0, 0, 0, 0.07)) drop-shadow(25px 23px 21px rgba(0, 0, 0, 0.24)) drop-shadow(11px 10px 15px rgba(0, 0, 0, 0.4)) drop-shadow(3px 3px 8px rgba(0, 0, 0, 0.47)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.47));
}

/* animation credential Store */



@keyframes left {
  0% {
    transform: translateX(-64px);
  }
  100% {
    transform: translateX(-1000px);
  }
  
}
@keyframes right {
  0% {
    transform: translateX(-1000px);
  }
  
  100% {
    transform: translateX(-64px);
  }
}

.left {
    animation-name: left;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-duration: 50s;
}
.right {
    animation-name: right;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-duration: 50s;
    
}


@media only screen and (max-width: 700px) {
    /* .header {
        font-size: 30px;
        line-height: 40px;
    } */

    .big-header {
        font-size: 55px;
        line-height: 64px;
    }

    .text_bg {
        font-size: 30px;
    }

    li.react-multi-carousel-item.carousel-item-padding-40-px {
        padding-right: 0px !important;
        padding-bottom: 45px !important;

    }

    /* .knoct_header {
        font-size: 45px;
    } */

    .extra_padding {
        padding: 20px
    }

    .team_text h1 {
        font-size: 17px;
    }

    .team_text p {
        font-size: 16px;
    }

    html,
    body {
        overflow-x: hidden;
        width: 100%;
    }

    .mobile_carousal {
        display: block;
    }
    .others_display_content{
        display: none;
    }
}

@media (min-width: 701px) {

    /* .knoct_header {
        font-size: 50px;
    } */

    .knoct_text {
        font-size: 14px;
        line-height: 30px;
    }

    .text_bg {
        font-size: 48px;
    }

    .main_padding {
        padding: 0 20px
    }

    .extra_padding {
        padding: 20px
    }

    .team_text p {
        font-size: 12px;
    }

    html,
    body {
        overflow-x: hidden;
        width: 100%;
    }
    .mobile_carousal {
        display: none;
    }
    .others_display_content{
        display: block;
    }
}


@media (min-width: 768px) {
    .big-header {
        font-size: 60px;
        line-height: 104px;
    }

    /* .knoct_header {
        font-size: 43px;
    } */

    .knoct_text {
        font-size: 14px;
        line-height: 30px;
    }

    .text_bg {
        font-size: 30px;
    }

    .main_padding {
        padding: 0 32px
    }

    .extra_padding {
        padding: 60px 120px
    }

    .team_text h1 {
        font-size: 16px;
    }

    .team_text p {
        font-size: 12px;
    }

    .store_certificate {
        width: 300px;
        height: 180px;
    }
    .mobile_carousal {
        display: none;
    }
    .others_display_content{
        display: block;
    }
}


@media ((min-width: 992px) and (max-width:1299px)) {
    .btn-solid{
        padding: 10px 20px;
       
        }
    .big-header {
        font-size: 76px;
        line-height: 104px;
    }

    /* .knoct_header {
        font-size: 60px;
    } */

    .knoct_text {
        font-size: 16px;
        line-height: 30px;
    }

    .text_bg {
        font-size: 40px;
    }

    .main_padding {
        padding: 0 64px
    }

    .extra_padding {
        padding: 60px 120px
    }

    /* .header {
        font-size: 50px;
    } */

    .team_text h1 {
        font-size: 17px;
    }

    .team_text p {
        font-size: 14px;
    }

    .store_certificate {
        width: 340px;
        height: 200px;
    }
    .mobile_carousal {
        display: none;
    }
    .others_display_content{
        display: block;
    }
}

@media ((min-width: 1020px) and (max-width:1200px)) {


    .store_certificate {
        width: 280px !important;
        height: 200px;
    }
}

@media (min-width: 1300px) {
    .big-header {
        font-size: 96px;
        line-height: 120px;
    }

    /* .knoct_header {
        font-size: 96px;
    } */

    .knoct_text {
        font-size: 16px;
        line-height: 30px;
    }

    .text_bg {
        font-size: 48px;
    }

    .main_padding {
        padding: 0 120px
    }

    .team_text h1 {
        font-size: 24px;
    }

    .team_text p {
        font-size: 16px;
    }

    .store_certificate {
        width: 388px;
        height: 247px;
    }
    .mobile_carousal {
        display: none;
    }
    .others_display_content{
        display: block;
    }
}