.heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 72px;
    line-height: 90px;
    align-items: center;
    text-align: center;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.heading_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    leading-trim: both;
    text-edge: cap;
    align-items: center;
    text-align: center;
    color: #CBC5CF;

}

.pricing_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #E5E2E7;
}

@media only screen and (max-width: 700px) {
    .heading {       
        font-size: 50px;
    }
}

@media (min-width: 768px) {
    .heading {       
        font-size: 60px;
    }
}


@media ((min-width: 992px ) and (max-width:1299px)) {
    
 
}

